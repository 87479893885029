import { navigate } from 'gatsby'
import React, { useState } from 'react'
import { usePagination, useWindowSize } from '@hooks'
import { useQuery } from '@apollo/client'
import { CacheUser, Order, ResponseType, TableColumnType } from '@types'
import { AvatarCard, EmptyTable, Input, Link, MobileGolfClubCard, Pagination } from '@elements'
import { Table } from '@components'
import { order, user } from '@graphql'
import { AccountLayout, MobileAccountLayout } from '@layouts'
import SearchImage from '@images/search.svg'
import { get12HourFromDate, zeroPad } from '@utils'
import Arrow from '@images/back-arrow.svg'
import './order-history.scss'

const formatDate = (date: string | Date) => {
  const d = new Date(date)
  const day = d.getDate()
  const month = d.getMonth() + 1
  const year = d.getFullYear()

  return `${month}/${day}/${year}`
}

interface AccountOrderHistoryProps {}

const AccountOrderHistory: React.FC<AccountOrderHistoryProps> = () => {
  const { toRender: isMobileScreen } = useWindowSize(['mobile', 'landscape'])
  const { data, loading } = useQuery<ResponseType<CacheUser>>(user.FetchCurrentUser, {
    fetchPolicy: 'cache-only'
  })
  const currentUser = data && data.res

  if (!loading && !currentUser) navigate('/home/')
  if (!currentUser) return null

  const [search, setSearch] = useState('')

  const { content = [], pagination, refetch, fetchMore } = usePagination<Order>(
    order.FetchPaginateOrders,
    {},
    { fetchPolicy: 'no-cache' },
    1,
    6
  )
  const paginationTotalPages = pagination?.totalPages

  const handleOrderHistoryPagination = (page: number) => fetchMore({}, page)
  const handleOrderHistorySearch = async (search: string) => {
    setSearch(search)
    await refetch({ term: search })
  }

  const columns: TableColumnType[] = [
    {
      title: '',
      key: 'golfClub.golfClubModel.avatar',
      render(_, row) {
        const avatar = row.golfClub?.golfClubModel?.avatar || row.golfEquipment?.golfEquipmentModel?.avatar
        return <AvatarCard avatar={avatar} size={'xs'} />
      }
    },
    {
      title: 'Item',
      key: 'golfClub.golfClubType.title',
      render(_, row) {
        const title = row.golfClub?.golfClubModel?.title || row.golfEquipment?.golfEquipmentModel?.title
        return <>{title || '-'}</>
      }
    },
    {
      title: 'Brand',
      key: 'golfClub.brand.title',
      render(_, row) {
        return <>{row?.golfClub?.brand?.title || row.golfEquipment?.brand?.title || '-'}</>
      }
    },
    {
      title: 'Date & Time',
      key: 'dateWithTime'
    },
    {
      title: 'Order Number',
      key: 'number',
      render(number: number) {
        return number && zeroPad(number, 8)
      }
    },
    {
      title: 'Total',
      key: 'orderPrice'
    }
  ]

  const renderTable = () => {
    if (!isMobileScreen && !content.length) {
      return <EmptyTable title={loading ? 'Your Order History' : 'Your Order History is Empty'} />
    }

    if (!isMobileScreen)
      return (
        <Table
          columns={columns}
          rows={tableRowsByPaginateOrders}
          handleRowClick={row => navigate(`/account/order-history/${row.id}/details`)}
        />
      )
  }

  const renderMobileTable = () => {
    if (isMobileScreen && !content.length) {
      return <EmptyTable title={loading ? 'Your Order History' : 'Your Order History is Empty'} />
    }

    if (isMobileScreen)
      return content.map(order => {
        const avatar = order?.golfClub?.golfClubModel?.avatar || order.golfEquipment?.golfEquipmentModel?.avatar || ''
        const brand = order?.golfClub?.brand?.title || order?.golfEquipment?.brand.title || '-'
        const title = order?.golfClub?.golfClubModel?.title || order?.golfClub?.golfClubModel.title || ''
        const date = order?.golfClub?.insertedAt
          ? formatDate(order?.golfClub?.insertedAt)
          : formatDate(order?.golfEquipment?.insertedAt)

        const data = [
          {
            label: 'Date',
            value: date || '-'
          },
          {
            label: 'Price',
            value: (order?.golfClub?.price || order?.golfEquipment?.price || '-').toString()
          },
          {
            label: 'Total',
            value: (order?.buyerTotalPrice || order?.buyerTotalPrice || '-').toString()
          }
        ]

        return (
          <MobileGolfClubCard
            key={order.id}
            avatar={avatar}
            subtitle={brand}
            title={title}
            data={data}
            footer={
              <Link
                to={`/account/order-history/${order.id}/details`}
                className={'primary account-order-history-card-more-button'}
              >
                <strong>More</strong>
                <Arrow />
              </Link>
            }
          />
        )
      })
  }

  const tableRowsByPaginateOrders = content.map(order => {
    const { insertedAt } = order
    const orderPrice = '$' + order.buyerTotalPrice
    const monthNumber = new Date(insertedAt).getMonth() + 1
    const date = `${monthNumber}/${new Date(insertedAt).getDate()}/${new Date(insertedAt).getFullYear()}`
    const time = get12HourFromDate(new Date(insertedAt))
    const dateWithTime = `${date} ${time}`

    return { ...order, orderPrice, dateWithTime }
  })

  const Layout = isMobileScreen ? MobileAccountLayout : AccountLayout
  return (
    <Layout title={'My Order history'} pageClassName={'account-order-history'}>
      {!isMobileScreen && <h6 className={'account-layout-page-title'}>MY ORDER HISTORY</h6>}
      <Input
        name={'golfClubsSearch'}
        placeholder={'Search an order...'}
        value={search}
        onChange={handleOrderHistorySearch}
        onTimesClick={() => handleOrderHistorySearch('')}
        prefix={<SearchImage />}
      />
      <div className={'account-order-history-table'}>
        {renderTable()}

        {renderMobileTable()}
        {(paginationTotalPages || 0) < 2 ? null : <Pagination {...pagination} onClick={handleOrderHistoryPagination} />}
      </div>
    </Layout>
  )
}

export default AccountOrderHistory
